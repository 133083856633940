const questions = [
    "… am See wohnt.",
    "… ein Haustier hat.",
    "… einen BMW fährt.",
    "… stricken kann.",
    "… mindestens vier Sprachen spricht.",
    "… schon mal in New York war.",
    "… Brot bäckt.",
    "… Takamaka mag.",
    "… (Wirtschafts-) Informatik studiert hat.",
    "… im Fernsehen war.",
    "… Einzelkind ist.",
    "… mit der Braut zur Schule/Uni gegangen ist.",
    "… ein MacBook hat.",
    "… in München arbeitet oder gearbeitet hat.",
    "… einen Dreitausender erklommen hat. ",
    "… einen Wehrdienst geleistet hat.",
    "… Yoga macht.",
    "… eine mehrtägige Fahrradtour gemacht hat.",
    "… keinen Kaffee mag.",
    "... dieses Jahr Skifahren war.",
    "… in einem Schaltjahr geboren wurde.",
    "… ein Musikinstrument spielen kann.",
    "… schon mal beim Schloss Neuschwanstein war.",
    "… noch nie auf dem Oktoberfest war."
  ];
  
  export default questions;
  