const userNames = [
    "Kathi",
    "Wilhelm",
    "Valentina",
    "Alex",
    "Hildegard",
    "Klaus",
    "Johannes",
    "Theresa",
    "Stefanie",
    "Markus",
    "Magdalena",
    "Christoph",
    "Manfred",
    "Marina",
    "Martina",
    "Clemens",
    "Frani",
    "Christian",
    "Claudia",
    "Veronika",
    "Alexander",
    "Nathalie",
    "Beni",
    "Oliver",
    "Waltraud",
    "Criti",
    "Ulli",
    "Gerhard",
    "Mathias",
    "Antonia",
    "Jasmin",
    "Valentin",
    "Lothar",
    "Sarah",
    "Michi"
];


export default userNames;